import React from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import smallFeature from "../../img/smallFeature.png";
import mvpDev from "../../img/mvpDev.png";
import consul from "../../img/consult.png";
import { Link } from "gatsby";
const OurOfferings = () => {
  return (
    <div className="container py-4 py-xl-5">
      <div className="row mb-5">
        <div className="col-md-8 col-xl-6 text-center mx-auto">
          <h2 className="text-4xl font-sans font-bold">
            Our Offerings
          </h2>
        </div>
      </div>

      <div className="relative  bg-white">
        <div className="relative container m-auto px-6 text-gray-500 md:px-12 z-10">
          <div className="grid lg:grid-cols-3 gap-6 md:w-8/12 md:mx-auto lg:w-full">
            <div className="group bg-white rounded-xl shadow-xl px-8 py-12 space-y-6 text-center">
              <img
                className="mx-auto w-24"
                src={mvpDev}
                alt="illustration"
                
              />
              <h3 className="text-2xl font-sans font-semibold text-gray-800">
                Minimal Viable Product (MVP) Development
              </h3>
              <p className="font-sans">
                MVP is a fascinating way to test your Idea into Production .
                Together we develop your initial iteration of your product with
                the ideal feature set at the quickest time to market.
              </p>
              <Link
                to="/our-offerings/#mvpservices"
                className="relative flex justify-center items-center h-10 w-10 mx-auto text-decoration-none
                                   before:absolute before:inset-0 before:border before:rounded-full before:transition before:duration-300 group-hover:before:scale-125"
              >
                <ArrowCircleRightOutlinedIcon style={{ scale: "1.5" }} />
              </Link>
            </div>
            <div className="group bg-white rounded-xl shadow-xl px-8 py-12 space-y-6 text-center">
              <img
                className="mx-auto w-24"
                src={smallFeature}
                alt="illustration"
                
              />
              <h3 className="text-2xl font-sans font-semibold text-gray-800">
                Small Feature Development
              </h3>
              <p className="font-sans">
                We assist in the development and improving of an existing
                product by selecting a scope of work from your extensive product
                roadmap. Size options range from 20 hours to 800 hours.
              </p>

              <Link
                to="/our-offerings/#smallfeature"
                className="relative flex justify-center items-center h-10 w-10 mx-auto 
                                   before:absolute before:inset-0 before:border before:rounded-full before:transition before:duration-300 group-hover:before:scale-125"
              >
                <ArrowCircleRightOutlinedIcon style={{ scale: "1.5" }} />
              </Link>
            </div>
            <div className="group bg-white rounded-xl shadow-xl px-8 py-12 space-y-6 text-center">
              <img
                className="mx-auto w-24"
                src={consul}
                alt="illustration"
                
              />
              <h3 className="text-2xl font-sans font-semibold text-gray-800">
                Technology Consulting
              </h3>
              <p className="font-sans">
                Are you a Subject Matter expert in your business domain? Are you
                in need of an IT consulting to pick the right solution for your
                business needs? We do technology consulting by educating you on
                required areas, evaluating products and vendors, reviewing
                architecture, etc.
              </p>
              <Link
                to="/our-offerings/#techconsulting"
                className="relative flex justify-center items-center h-10 w-10 mx-auto 
                                   before:absolute before:inset-0 before:border before:rounded-full before:transition before:duration-300 group-hover:before:scale-125"
              >
                <ArrowCircleRightOutlinedIcon style={{ scale: "1.5" }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurOfferings;
