import React, { useCallback } from "react";
import Lottie from "react-lottie";
import heroLottie from "../../lottie-files/hero-lottie.json";
import ContactModalButton from "../utils/contactModal";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particleObject from "../utils/particleObject";

const HeroSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: heroLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log();
  }, []);
  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleObject}
        style={{ zIndex: "-1", height: "600px" }}
      />
      <div
        className="container  d-inline flex-column-reverse py-5 py-xl-5 mt-5 mx-auto mt-xs-20 "
        style={{ paddinLeft: "8px" }}
      >
        <div className="row bg-header flex-row-reverse py-4">
          <div className="col-md-6 offset-sm-0" style={{ height: "70%" }}>
            <div
              className="order-first p-xl-5 m-xl-5 mx-auto px-3"
              style={{ width: "90%", height: "70%" }}
            >
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <div className="col-md-6 text-center text-md-start d-flex px-3 d-sm-flex  d-md-flex justify-content-center align-items-center justify-content-sm-start align-items-sm-end justify-content-md-start align-items-md-center justify-content-xl-center">
            <div>
              <h1
                className="text-nowrap text-3xl font-sans fs-1 fw-bold text-start text-sm-center xs: mt-10  d-sm-flex"
                style={{
                  
                  color: "rgb(0,0,0)",
                  textAlign: "center",
                }}
              >
                Hello, We are the MVP Tribe
              </h1>
              <p
                className="text-sm-center font-sans text-md-start my-3"
                style={{
                  
                  fontSize: "25px",
                  color: "rgb(59,65,71)",
                }}
              >
                We build bridges
              </p>
              <ContactModalButton buttonName={`Let's Talk  💭 `} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeroSection;
