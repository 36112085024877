import React from "react";
import { graphql, Link } from "gatsby";
import HeroSection from "../components/HomePage/heroSection";
import GettingStarted from "../components/HomePage/gettingStarted";
import OurOfferings from "../components/HomePage/ourOfferingsSection";
import TechnologyFocusBanner from "../components/HomePage/technologyFocus";
import CalltoActionSection from "../components/HomePage/calltoAction";
import BannerImage from "../img/bridge.png";
import SiteSEO from "../components/siteSEO";
import WhyMVPTribe from "../components/HomePage/whyMVPTribe";

function IndexPage({ data: { allGraphCmsPost } }) {
  return (
    <div>
      <SiteSEO
        title={"Home | MVP Tribe"}
        description={
          "MVP Tribe is a registered trademark of Trusty Bytes Private Limited. The unit singularly focus on fast and smart development of small scope of work for individual subject matter experts and small and large enterprises"
        }
        keywords={"MVP Development , Software Development, MVP Tribe"}
        image={""}
      />
      <div className="divide-y divide-gray-200">
        {/* Hero Section */}
        <HeroSection className="circles" />
        {/* Banner */}

        <picture
          className="flex-shrink-1 flex-fill flex-xl-grow-1 flex-xxl-grow-1"
          style={{ width: "100%" }}
        >
          <img
            className="img-fluid flex-fill flex-lg-grow-1 flex-xl-grow-1 flex-xxl-grow-1"
            src={BannerImage}
            alt="Bridge Banner"
            style={{ width: "100%", transform: "scale(1.05)" }}
          />
        </picture>
        {/* Why MVP Tribe Section */}

        <WhyMVPTribe />
        {/* Getting Started Section */}
        <GettingStarted className="px-5" />
        {/* Our Offerings Section */}
        <OurOfferings />
        {/* Technology Focus Banner Section */}
        <TechnologyFocusBanner />
        {/* Call to Action Section */}
        <CalltoActionSection />
      </div>
      <>
        <div className="pt-6 pb-8 space-y-2 md:space-y-5 ">
          <h1 className="text-3xl leading-9 font-sans font-extrabold text-gray-900 tracking-tight sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            Our Latest Insights
          </h1>
        </div>

        <div className="py-16 bg-gray-50">
          <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="grid gap-12 md:grid-cols-3">
              {allGraphCmsPost.nodes
                .filter((item, index) => index < 3)
                .map((post) => {
                  return (
                    <div className="group space-y-4 shadow-sm">
                      <img
                        src={post.coverImage.url}
                        alt="art cover"
                        
                        width="1000"
                        height="667"
                        className="h-64 w-full shadow object-cover object-top transition duration-500 rounded-lg group-hover:rounded-3xl"
                      />
                      <div className="space-y-2 px-3">
                        <div className="space-y-4">
                          <h4 className="text-2xl font-sans font-semibold text-gray-700">
                            {post.title}
                          </h4>
                          <p className="text-gray-600 font-sans">{post.excerpt}</p>
                        </div>
                      </div>
                      <Link
                        to={`/posts/${post.slug}`}
                        className="block w-max px-3 py-2 text-cyan-600"
                      >
                        Read more{" "}
                      </Link>
                    </div>
                  );
                })}
            </div>
            <h1 style={{ fontSize: "1.5em", marginTop: "2%" }}>
              <Link to="/mvp-insights" className="text-gray-600 font-sans">
                More Insights
              </Link>
            </h1>
          </div>
        </div>

      </>
    </div>
  );
}

export const indexPageQuery = graphql`
  {
    allGraphCmsPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        date: formattedDate
        excerpt
        slug
        title
        coverImage {
          url
        }
      }
    }
  }
`;

export default IndexPage;
