import React from "react";
import ContactModalButton from "../utils/contactModal";
const CalltoActionSection = () => {
  return (
    <section className="py-4 py-xl-5">
      <div className="container" style={{ borderRadius: "12px" }}>
        <div
          className="bg-light border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5"
          style={{ background: "rgb(15,63,111)", borderRadius: "24px" }}
        >
          <div className="pb-2 pb-lg-1">
            <h2
              className="fw-bold mb-2 font-sans"
              style={{  fontWeight: "bold" }}
            >
              Have an Idea ?
            </h2>
            <p
              className="mb-0 font-sans"
              style={{  fontSize: "23px" }}
            >
              We can help you build your Vision
            </p>
          </div>
          <div>
            <ContactModalButton buttonName={"Write to us 💭"} style={{}} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default CalltoActionSection;
