const particleObject = {
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: false,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      repulse: {
        distance: 100,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: ["#1B1D64", "#0482DE", "#04B6DE", "DE4204", "4DC337"],
      opacity: 0.5,
    },

    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: true,
      speed: 1,
      straight: true,
    },
    number: {
      density: {
        enable: true,
        area: 1000,
      },
      value: 15,
    },
    opacity: {
      value: 0.1,
    },
    shape: {
      type: ["circle", "square"],
    },
    size: {
      value: { min: 10, max: 30 },
    },
  },
  detectRetina: true,
};

export default particleObject;
