import React from "react";
import Lottie from "react-lottie";
import coreValuesLottie from "../../lottie-files/core-values-lottie.json";
import "@fortawesome/fontawesome-svg-core";
function WhyMVPTribe() {
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: coreValuesLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="relative bg-white dark:bg-gray-800 p-4">
      <div className="col text-start font-sans text-md-center flex-column justify-content-center flex-wrap align-items-md-center align-items-xxl-center">
        <h1
          style={{
            fontSize: "36px",
            color: "Black",
            fontWeight: "bold",
            textAlign: "center",
            paddingLeft: "14px",
          }}
        >
          Why MVP Tribe ?
        </h1>
      </div>
      <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
        <div className="lg:col-start-2 md:pl-20">
          <ul className="mt-5">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="bi bi-people-fill"
                      style={{ transform: "scale(1.50)" }}
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                      <path
                        fillRule="evenodd"
                        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                      ></path>
                      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5
                    className="text-lg leading-6 text-gray-900 font-sans dark:text-white font-bold"
                    style={{ fontSize: "26px" }}
                  >
                    Customer Focus
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500  font-sans dark:text-gray-300">
                    Understand and adhere to the Customer Vision.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ transform: "scale(1.50)" }}
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-stopwatch-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5
                    className="text-lg leading-6 text-gray-900  font-sans dark:text-white font-bold"
                    style={{ fontSize: "26px" }}
                  >
                    Accelerated Delivery
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500 font-sans dark:text-gray-300">
                    Focus on time to market, 2 to 6 weeks Delivery.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="bi bi-stopwatch-fill"
                      style={{ transform: "scale(1.50)" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5
                    className="text-lg leading-6 text-gray-900 font-sans dark:text-white font-bold"
                    style={{ fontSize: "26px" }}
                  >
                    Core Values
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500 font-sans dark:text-gray-300">
                    Delivering trust-based engagement in everything we do.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-64 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      style={{ transform: "scale(1.50)" }}
                    >
                      <path d="M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM191.4 .0132C89.44 .3257 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.61 288.9-.2837 191.4 .0132zM192 96.01c-44.13 0-80 35.89-80 79.1C112 184.8 104.8 192 96 192S80 184.8 80 176c0-61.76 50.25-111.1 112-111.1c8.844 0 16 7.159 16 16S200.8 96.01 192 96.01z"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5
                    className="text-lg leading-6 text-gray-900 font-sans dark:text-white font-bold"
                    style={{ fontSize: "26px" }}
                  >
                    Smart Strategies
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500 font-sans dark:text-gray-300">
                    Valued in Design Thinking and Fail Fast Approach.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="mt-10 -mx-8 md:-mx-8 relative lg:mt-0  lg:col-start-1">
          <Lottie
            options={defaultOptions}
            className="relative mx-auto shadow-lg rounded w-auto"
          />
        </div>
      </div>
    </div>
  );
}
export default WhyMVPTribe;
