import React from "react";
import TechnologyFocusImage from "../../img/technologyStack.png";
const TechnologyFocusBanner = () => (
  <>
    <div className="container  font-sans" style={{ zIndex: "10" }}>
      <h1
        style={{
          fontSize: "36px",
          color: "Black",
          fontWeight: "bold",
          textAlign: "center",
          paddingLeft: "14px",
          paddingBottom: "5px",
          paddingTop: "10px",
        }}
      >
        Technology Focus
      </h1>
      <img
        className="d-xxl-flex align-items-center "
        src={TechnologyFocusImage}
        alt="Technology focus"
        
      />
    </div>
  </>
);
export default TechnologyFocusBanner;
