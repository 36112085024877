import React from "react";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import Looks4OutlinedIcon from "@mui/icons-material/Looks4Outlined";

const GettingStarted = () => {
  return (
    <div className=" container text-start py-4 py-xl-5 z-10">
      <div className="row gx-2 gy-1 text-md-center d-lg-flex d-xl-flex d-xxl-flex align-items-lg-center align-items-xl-center ">
        <div className="col text-start text-md-center font-sans flex-column justify-content-center flex-wrap align-items-md-center align-items-xxl-center">
          <h1
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "rgb(0,78,193)",
              textAlign: "center",
              paddingLeft: "14px",
              paddingBottom: "2%"
            }}
          >
            Getting Started
          </h1>
          <p
            className="text-center d-flex font-sans d-lg-flex d-xl-flex d-xxl-flex flex-column align-items-lg-center align-items-xl-center justify-content-xxl-end align-items-xxl-center w-lg-50"
            style={{
              
              textAlign: "center",
              
              fontSize: "24px",
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Our&nbsp;Simple and inclusive / collaborative approach to
              jump-start the journey towards your Goal
            </span>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Our focus is to get you started on your path without losing any
              time.
            </span>
          </p>
         
        </div>
      </div>

      <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <div className="mt-2">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
          </div>

          <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
            <div className="lg:flex lg:w-120 lg:justify-left">
              <img
                className="w-[32rem] h-[32rem]  flex-shrink-0 object-cover xl:w-120 xl:h-100 rounded-full xl:pr-40 "
                src="https://cdn3d.iconscout.com/3d/premium/thumb/financial-consultation-5583648-4658310.png"
                alt="Getting Started "
                
              />
            </div>
            <div className="w-full lg:w-1/2 grid grid-cols-1 gap-8 xl:gap-16 md:grid-cols-2">
              <div className="space-y-3">
                <span className="inline-block p-1 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                  <LooksOneOutlinedIcon style={{ scale: "1.5" }} />
                </span>
              
                <h1 className="text-2xl text-left font-semibold font-sans text-gray-700 capitalize dark:text-white">
                  Idea Submission
                </h1>

                <p className="text-gray-600 font-sans dark:text-gray-300">
                  The Client submits the Idea. We collaborate with them to
                  understand their vision and goals of the product.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-1 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                  <LooksTwoOutlinedIcon style={{ scale: "1.5" }} />
                </span>

                <h1 className="text-2xl text-left font-semibold font-sans text-gray-700 capitalize dark:text-white">
                  Formulate a Scope
                </h1>

                <p className="text-gray-600  font-sans dark:text-gray-300">
                  We perform an agile and sharp analysis to strategically select
                  and prioritise the features and technology for the MVP.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-1 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                  <Looks3OutlinedIcon style={{ scale: "1.5" }} />
                </span>

                <h1 className="text-2xl text-left font-semibold font-sans text-gray-700 capitalize dark:text-white">
                  Finalize scope / Terms
                </h1>

                <p className="text-gray-600 font-sans dark:text-gray-300">
                  The scope, schedule and other relevant terms can be agreed
                  upon swiftly.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-1 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                  <Looks4OutlinedIcon style={{ scale: "1.5" }} />
                </span>

                <h1 className="text-2xl text-left font-sans font-semibold text-gray-700 capitalize dark:text-white">
                  Implementation
                </h1>

                <p className="text-gray-600 font-sans dark:text-gray-300">
                  We code and configure deftly as an Expert Minimum Viable
                  Product Development firm to guarantee swift and error-free
                  delivery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GettingStarted;
